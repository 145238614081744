/* ------------------------------ Base ------------------------------ */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./fontawesome/css/all.min.css";

@font-face {
    font-family: 'Kanit-Regular';
    src: url('~fonts/Kanit-Regular.ttf') format("truetype");
}
/* custom scrollbar */
::-webkit-scrollbar {
    width: 18px;
    @apply cursor-pointer;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    /* background-color: #d6dee1; */
    @apply bg-gray-300 bg-opacity-50 cursor-pointer;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}
html, body { 
    @apply h-full text-slate-800;
}
body { 
    --bg-color: #fff;
    --txt-color: #000;
    --scrollbar-width: 0px;
    --hundred-vw: calc(100vw - var(--scrollbar-width, 0px));
    --hundred-vh: 100vh;
    --layout-ratio: calc(937px / 1920px);
    --hundred-vh-wratio: calc(100vw * 0.488);
    --layout-padding: 0px;
    --grid-gap: 16px;
    --inner-width: calc(var(--hundred-vw) - 32px);
    --column-count: 6;
    --column-unit: calc((var(--inner-width) + var(--grid-gap)) / var(--column-count));
    --body-padding: 16px;
    @apply m-0 font-body w-[var(--hundred-vw)] overflow-x-hidden;
}
#root, section{
    @apply w-[var(--hundred-vw)];
}
h1{
    @apply text-5xl;
}
h2{
    @apply text-xl;
}
p{
    @apply text-xl;
}
/* ------------------------------ Font ------------------------------ */
.prompt-thin {
    font-family: "Prompt", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .prompt-extralight {
    font-family: "Prompt", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .prompt-light {
    font-family: "Prompt", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .prompt-regular {
    font-family: "Prompt", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .prompt-medium {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .prompt-semibold {
    font-family: "Prompt", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .prompt-bold {
    font-family: "Prompt", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .prompt-extrabold {
    font-family: "Prompt", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .prompt-black {
    font-family: "Prompt", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .prompt-thin-italic {
    font-family: "Prompt", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .prompt-extralight-italic {
    font-family: "Prompt", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .prompt-light-italic {
    font-family: "Prompt", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .prompt-regular-italic {
    font-family: "Prompt", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .prompt-medium-italic {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .prompt-semibold-italic {
    font-family: "Prompt", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .prompt-bold-italic {
    font-family: "Prompt", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .prompt-extrabold-italic {
    font-family: "Prompt", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .prompt-black-italic {
    font-family: "Prompt", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  
/* ------------------------------ Font Size ------------------------------ */
.text-small{
    @apply text-base;
}
.text-normal{
    @apply text-lg;
}
.text-large{
    @apply text-xl;
}
.text-small h1{
    @apply text-5xl;
}
.text-normal h1{
    @apply text-5xl;
}
.text-large h1{
    @apply text-5xl;
}
.text-small h2{
    @apply text-4xl;
}
.text-normal h2{
    @apply text-4xl;
}
.text-large h2{
    @apply text-4xl;
}
.text-small h3{
    @apply text-3xl font-semibold;
}
.text-normal h3{
    @apply text-3xl font-semibold;
}
.text-large h3{
    @apply text-3xl font-semibold;
}
.text-small h4{
    @apply text-2xl;
}
.text-normal h4{
    @apply text-2xl;
}
.text-large h4{
    @apply text-2xl;
}
.text-small h5{
    @apply text-xl;
}
.text-normal h5{
    @apply text-2xl;
}
.text-large h5{
    @apply text-3xl;
}
.text-small p,.text-small button, .text-small input, .text-small optgroup, .text-small select, .text-small textarea {
    @apply text-base;
}
.text-normal p,.text-normal button, .text-normal input, .text-normal optgroup, .text-normal select, .text-normal textarea {
    @apply text-lg;
}
.text-large p,.text-large button, .text-large input, .text-large optgroup, .text-large select, .text-large textarea {
    @apply text-xl;
}
.text-small footer p{
    @apply text-base;
}
.text-normal footer p{
    @apply text-lg;
}
.text-large footer p{
    @apply text-xl;
}
/* set text */
/* Go to top button */
.gototop-btn {
  @apply fixed justify-center items-center z-40 bottom-8 right-8 border-0 w-12 h-12 rounded-full drop-shadow-md shadow-xl bg-[#676aad] bg-opacity-60 text-white font-bold cursor-pointer hover:bg-opacity-80;
}
/* ------------------------------ Header ------------------------------ */
header{
    @apply sticky flex justify-center top-0 z-50 w-full lg:opacity-95 min-h-[112px] bg-white;
    /* @apply shadow-lg border-b; */
}
header.drop-shadow{
    @apply shadow-lg border-b;
}
nav{
    @apply w-full relative;
}
.container{
    @apply w-full flex justify-between px-5 mx-auto pt-5 pb-1;
}
.menu .container{
    @apply p-0 py-2;
}
.top-nav .menu{
    @apply flex justify-end pb-2 pr-5;
}
.mobile-menu{
    @apply block lg:hidden;
}
.desktop-menu{
    @apply hidden lg:block;
}
.desktop-menu ul{
    @apply flex flex-row flex-wrap justify-end;
}
.desktop-menu ul li{
    @apply flex relative whitespace-nowrap px-5 py-2;
}
.desktop-menu .submenu{
    @apply flex flex-col absolute top-0 z-10;
}
.desktop-menu ul li a{
    @apply w-full whitespace-pre  text-black hover:text-red-pallate-main;
}
.homepage-layout .desktop-menu ul li a{
    @apply w-full whitespace-pre  text-white hover:text-red-pallate-main;
}
.desktop-menu .submenu ul{
    @apply flex absolute flex-col top-10 shadow-lg ring-1 ring-pallate-main/5 bg-white rounded;
}
.desktop-menu .submenu ul li{
    @apply  hover:bg-red-pallate-main/5 min-w-[158px] max-w-[371px] first:rounded-t last:rounded-b;
}
.desktop-menu .submenu ul li a{
    @apply text-black hover:text-red-pallate-main hover:no-underline;
}
.desktop-menu .submenu ul li,.desktop-menu .submenu ul li{
    @apply list-none hover:list-disc;
}
.desktop-menu ul li.active .submenu ul li a{
    @apply text-black hover:text-red-pallate-main;
}
.desktop-menu ul li.active a,.desktop-menu ul li.active .submenu ul li.active a{
    @apply text-red-pallate-main;
}
.mobile-menu .mobile-nav{
    @apply hidden;
}
.mobile-nav-open{
    @apply px-3 py-3 rounded ring-1 ring-pallate-main/5 shadow-lg text-red-pallate-main hover:bg-pallate-main/5;
}
.mobile-menu .mobile-nav.active{
    @apply flex items-start flex-col fixed top-0 right-0 w-screen h-screen bg-white;
}
.mobile-menu .active ul{
    @apply flex w-full flex-col items-center;
}
.mobile-menu .active li{
    @apply w-full px-8 justify-start flex flex-col;
}
.mobile-menu .active .ul-menu-nav{
    @apply z-0 py-5 border-b border-pallate-main border-dotted;
}
.mobile-menu .active .top{
    @apply z-20 py-8;
}
.mobile-menu .active .ul-menu-nav li a{
    @apply w-full flex before:content-['\25AA'] before:mr-5 hover:text-red-pallate-main first-letter:text-red-pallate-main first-letter:text-xl py-2;
}
.mobile-menu .mobile-nav.active ul.top{
    @apply flex h-[50px] w-screen flex-row justify-between border-b border-red-pallate-main border-dotted;
}
.mobile-menu .mobile-nav.active ul.top li{
    @apply w-fit px-5 pl-8 text-red-pallate-main;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language{
    @apply relative text-black h-[50px] flex items-center justify-center;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language img{
    @apply w-[36px] min-w-[36px];
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language div{
    @apply flex px-0 w-full flex-row justify-around items-center text-sm;
}
.mobile-menu .mobile-nav.active ul.top svg{
    @apply text-xs ml-5;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language .submenu{
    @apply right-0 top-10 absolute bg-white text-sm rounded text-red-pallate-main shadow-lg;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language .submenu li{ 
    @apply p-0;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language .submenu li button{ 
    @apply hover:bg-red-pallate-main/5;
    @apply flex px-5 pr-8 w-full flex-row py-3 justify-center items-center;
}
.mobile-menu-language div{
    @apply whitespace-nowrap relative items-start justify-start z-10;
}
.mobile-menu-language img{
    @apply h-[15px] pr-3;
}
.mobile-menu .mobile-nav.active ul li.active .submenu ul li a{
    @apply text-black;
}
.mobile-menu .mobile-nav.active  ul li.active a,.mobile-menu .mobile-nav.active ul li.active .submenu ul li.active a{
    @apply text-red-pallate-main;
}
.mobile-menu .active .ul-menu-info li.info{
    @apply text-red-pallate-main flex flex-row whitespace-pre-line items-end py-2;
}
.mobile-menu .active .ul-menu-info li.info h3{
    @apply pl-3;
}
.ul-menu-info li.open{
    @apply flex flex-row items-baseline whitespace-pre;
}
.ul-menu-info .info img{
    @apply min-w-[102px];
}
.ul-menu-info li.info{
    @apply my-2 mt-5 flex flex-col;
}
.ul-menu-info li.open p{
    @apply text-sm text-black;
}
.ul-menu-info li.font-tool {
    @apply flex flex-row items-center my-2;
}
.ul-menu-info li.font-tool p{
    @apply text-sm text-black whitespace-nowrap;
}
.ul-menu-info li.font-tool ul{
    @apply flex flex-row;
}
.ul-menu-info li.font-tool ul li{
    @apply w-fit px-1;
}
.ul-menu-info li.open p:first-child{
    @apply first-letter:text-red-pallate-main text-black first-letter:text-xl text-base pr-3;
}
.ul-menu-info .social, .article-content .social {
    @apply flex flex-row;
}
.ul-menu-info .social ul, .article-content .social ul {
    @apply flex flex-row justify-center w-fit;
}
.ul-menu-info .social ul li, .article-content .social ul li{
    @apply mr-2 px-0 py-4;
}
.ul-menu-info .social .rounded-btn{
    @apply rounded-full bg-black p-3 w-fit text-white hover:bg-red-pallate-main;
}
header{
    @apply sticky;
}
.homepage-layout header{
    @apply fixed;
}
header .container{
    @apply flex flex-col;
}
header .logo{
    @apply flex items-center justify-between w-full;
}
header .logo-img img{
    @apply h-[35px] min-w-[80px] mr-2;
}
header .logo-img a:first-child img{
    @apply h-[40px];
}
header .logo .logo-img{
    @apply flex flex-row justify-center items-center ;
}
.header-info{
    @apply flex whitespace-pre flex-col justify-start text-sm pl-1 pb-2;
}
header .logo h3{
    @apply flex md:hidden lg:flex text-pallate-main whitespace-pre-line;
}
.top-nav {
    @apply flex-col flex
}
.top-nav .tool{
    @apply flex-row justify-end pb-2 pr-5 hidden lg:flex;
}
.top-nav .language, .top-nav .font-size{
    @apply h-fit cursor-pointer p-0 flex ml-3 rounded text-black text-sm items-center;
}
.top-nav li button{
    @apply h-[32px] flex items-center px-2 py-2 hover:text-pallate-main mr-1 last:mr-0 rounded-sm text-base;
}
.top-nav .font-size li:first-child button{
    @apply text-xs;
}
.top-nav .font-size li:last-child button{
    @apply text-2xl;
}
.top-nav li.active button{
    @apply text-pallate-main bg-pink-100/[0.8] hover:text-pallate-main;
}
.preloader{
    @apply w-screen h-screen text-center sticky top-0 left-0 bg-white flex justify-center items-center;
}
/* ------------------------------ Home Page ------------------------------ */
.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1.5s all ease;
}
.reveal.active{
    transform: translateY(0);
    opacity: 1;
}
.homepage{
    @apply flex flex-col w-[var(--hundred-vw)] justify-start items-start;
}
.blackscreen{
    @apply bg-black/90 flex w-screen h-screen justify-center items-center fixed z-50;
}
.popup{
    @apply bg-white rounded-lg w-4/6 h-fit lg:h-5/6 flex flex-col lg:flex-row;
}
.popup-info{
    @apply h-full w-full lg:w-1/2 p-10 flex justify-center items-center flex-col relative;
}
.popup-info p{
    @apply text-center;
}
.popup-info .close-popup{
    @apply absolute top-5 right-8 hover:text-pallate-bg hover:underline cursor-pointer text-sm opacity-75;
}
.popup-info h3{
    @apply py-5 text-center text-3xl lg:text-4xl 2xl:text-4xl !leading-[3rem] 2xl:!leading-[3rem];
}
.popup-info button{
    @apply bg-pallate-bg text-white w-3/5 py-8 mb-5 text-xl uppercase hover:bg-red-600 rounded-lg;
}
.popup-bg{
    @apply h-[0px] lg:h-full w-full lg:w-1/2 bg-cover bg-center;
}
.popup-bg img{
    @apply h-[0px] lg:h-full w-full rounded-t-lg lg:rounded-t-none rounded-l-none lg:rounded-l-lg;
}
.popup .logo{
    @apply flex items-center justify-between w-full;
}
.popup .logo-img{
    @apply flex items-center justify-center w-full flex-wrap gap-5;
}
.popup .logo-img img{
    @apply h-[50px] w-auto mr-2;
}
.popup .logo-img a:first-child img{
    @apply h-[80px];
}
.popup .logo .logo-img{
    @apply flex flex-row justify-center items-center ;
}
.homepage section{
    @apply py-10 flex w-full justify-center bg-repeat-x bg-left-top bg-contain;
}
.homepage section h1{
    /* @apply pb-12 w-full bg-clip-text bg-gradient-to-bl from-pallate-main to-pallate-main text-transparent ; */
    @apply pb-12 w-full ;
}
.homepage section h2,.homepage section h1{
    @apply text-pallate-4;
}
.homepage section h2 span,.homepage section h1 span{
    @apply text-pallate-2;
}
.main-slide{
    /* @apply w-[var(--hundred-vw)] relative h-[calc(var(--hundred-vh)*0.7)] md:h-[600px]; */
    /* @apply w-[var(--hundred-vw)] relative h-[500px] md:h-[calc(var(--hundred-vh)*0.65)]; */
    @apply w-[var(--hundred-vw)] relative h-fit;
}
.main-slide img{
    @apply w-full;
}
.main-slide .main-slide-info{
    @apply absolute text-pallate-highlight flex justify-start items-center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.main-slide .no{
    @apply text-[130px] md:text-[350px] font-bold pr-[20px];
}
.main-slide .detail{
    @apply flex justify-start items-start flex-col gap-1 md:gap-10 w-fit;
}
.main-slide .detail h4{
    @apply text-[19px] sm:text-[30px] md:text-[60px] font-semibold;
}
.main-slide .detail h5{
    @apply text-[14px] sm:text-[25px] md:text-[60px] whitespace-nowrap font-semibold tracking-[0.3rem];
}
.main-slide .detail p{
    @apply text-base md:text-2xl sm:whitespace-nowrap font-semibold;
}
.homepage .curve-svg{
    @apply min-h-[230px] lg:min-h-[367px] w-full relative;
}
.homepage .curve-svg svg{
    @apply absolute bottom-0 left-0;
}
.homepage .swiper-pagination.swiper-pagination-progressbar { 
    top: inherit; bottom: 0; 
}
.homepage .swiper-pagination-progressbar-fill {
    @apply bg-pallate-main;
}
.homepage .main-slide .card{
    /* @apply h-[50vh] md:h-[60vh] xl:h-[70vh]  */
    @apply relative flex flex-col md:flex-row;
}
.homepage .main-slide .card .image{
    /* @apply h-[370px] lg:h-[566px] w-full relative object-cover rounded-2xl overflow-hidden flex items-center cursor-pointer; */
    @apply h-[370px] lg:h-[420px] w-full relative object-cover overflow-hidden flex items-center cursor-pointer;
}
.homepage .main-slide .card .image-inner{
    @apply w-full h-full absolute bg-cover bg-center transition-all duration-500 ease-in-out transform ;
}
.project-overview {
    @apply flex flex-col justify-center items-center bg-white pb-16 w-full;
}
.project-overview .container{
    @apply flex justify-center items-center flex-col md:flex-row;
}
.project-overview .logo-img{
    @apply py-16 flex flex-row justify-around gap-10 items-center flex-wrap;
}
.project-overview .logo-img a{
    @apply w-fit flex;
}
.project-overview .logo-img a img{
    @apply h-[40px] md:h-[60px] w-fit;
}
.project-overview .logo-img a:first-child img{
    @apply h-[50px] md:h-[80px];
}
.project-overview .logo-img a:nth-child(4) img{
    @apply h-[70px] md:h-[120px];
}
.project-overview .logo-img a:last-child img{
    @apply h-[30px] md:h-[50px] w-fit;
}
.project-overview .info{
    @apply flex bg-pallate-main text-white flex-col lg:flex-row;
}
.project-overview .info p{
    @apply w-full p-8 sm:p-16 md:p-28 text-base sm:text-2xl 2xl:text-3xl leading-loose 2xl:leading-relaxed font-light 2xl:font-normal;
}
.project-overview .info p b{
    @apply font-normal sm:font-light
}
.project-overview .info img{
    @apply min-w-[40%] aspect-square;
}
.project-overview h3{
    @apply first-letter:text-pallate-main leading-loose;
}
.project-overview ul li b{
    @apply text-dark-0 first-letter:text-pallate-main text-xl inline-block;
}
.project-overview ul li {
    @apply pb-5 text-dark-white-3;
}
.project-overview .more-detail a{
    @apply text-pallate-3 hover:underline hover:text-pallate-2 uppercase;
}
.project-overview .more-detail {
    @apply pt-10 ;
}
.project-overview .day-info ul{
    @apply flex pt-16 flex-wrap w-full flex-col lg:flex-row;
}
.project-overview .day-info li{
    @apply flex lg:w-1/3 flex-col md:flex-row lg:flex-col px-10 md:px-0 lg:px-16;
}
.project-overview .day-info img{
    @apply px-0 md:px-10 lg:px-0 h-fit aspect-video w-full md:w-[320px] lg:w-full;
}
.project-overview .day-info h4{
    @apply py-5 text-red-pallate-2;
}
.schedule,.schedule .container{
    @apply bg-pallate-1 flex flex-col justify-center items-center w-full pb-8;
}
.schedule h3{
    @apply w-full py-5 pt-16 text-pallate-3;
}
.schedule table{
    @apply w-full mb-5;
}
.schedule thead th{
    @apply bg-pallate-4 text-white p-2 font-normal border-[#f2f2f2] border px-[10px] sm:px-[40px];
}
.schedule tbody td{
    @apply text-start first:text-center border-pallate-1 border-2 bg-[#fcfcfc] px-[10px] sm:px-[40px] py-[5px] align-top cursor-pointer;
}
.schedule tbody td ul{
    @apply gap-0;
}
.schedule tbody td span{
    @apply font-semibold first-letter:text-pallate-3 inline;
}
.schedule tbody td ul li span{
    @apply inline;
}
.schedule tbody td.btn {
    @apply text-center pb-[0.75rem] leading-[1.8rem];
}
.schedule tbody td.btn a{
    @apply text-center text-sm hover:text-pallate-bg rounded-md hover:bg-white my-3 py-1 px-4 hover:shadow-md bg-pallate-bg text-white hover:no-underline;
}
.project-overview-article{
    @apply text-slate-800;
}
.speaker{
    @apply flex flex-col px-16;
}
.speaker img{
    @apply w-[300px] h-[350px] rounded-lg shadow-lg;
}
.speaker ul li{
    @apply flex gap-5;
}
.speaker-info{
    @apply flex flex-col pl-5 pt-5;
}
.speaker-info p{
    @apply font-bold;
}
.speaker-info .detail{
}
.speaker .detail li{
    @apply before:text-pallate-3 before:content-['●'];
}
.workshop{
    @apply flex w-full flex-col md:flex-row bg-pallate-3;
}
.workshop .container{
    @apply flex  flex-col md:flex-row ;
}
.workshop .info{
    @apply w-full;
}
.workshop .title{
    @apply  w-full md:w-1/3 text-white font-light my-10 leading-[4rem] text-4xl flex flex-col !items-start !justify-start;
}
.workshop .title-underline{
    @apply w-[180px] h-[20px] bg-[#676aad] mt-6;
}
.workshop {
    @apply flex justify-center items-center;
}
.workshop ul{
    @apply flex w-full  flex-col md:flex-row justify-center items-center gap-5;
}
.workshop li{
    @apply w-full h-fit flex flex-col cursor-pointer p-5;
}
.workshop h3{
    @apply text-center w-full first-letter:text-pallate-3;
}
.workshop p{
    @apply text-right text-xl w-full h-fit flex justify-end items-end py-5 text-white;
    text-shadow: 2px 2px 3px black;
    /* transition: 0.6s; */
}
/* .workshop li:hover p{
    @apply visible;
} */
.workshop img{
    @apply w-full aspect-square;
}
.location{
    @apply flex flex-col md:flex-row justify-center items-center w-full h-fit bg-pallate-bg;
}
.location .map{
    @apply h-[520px] md:h-[480px] p-12 w-full md:w-3/5;
}
.location .info{
    @apply w-full md:w-2/5 px-12 md:pl-0 md:pr-8 pb-14 md:pb-14 mt-5 text-white h-full;
}
.location .info h3{
    @apply mb-5 uppercase text-2xl tracking-[0.2rem] font-light;
}
.location .info li{
    @apply py-5 text-base font-light before:content-["◼"] before:text-2xl before:mx-3 flex items-start;
}
.location .info li b{
    @apply w-full inline-block mb-1 text-white text-xl tracking-[0.1rem] font-light;
}
.location .info li .link{
    @apply text-white cursor-pointer hover:underline;
}
.location .info li .link i{
    @apply text-pallate-3;
}
.pagination{
  @apply flex flex-row items-center justify-start flex-nowrap text-pallate-main;
}
.center-line{
  @apply w-8 border-t-2 border-dashed border-pallate-main flex mx-2;
}
.art-slider-navigation {
    padding: 15px 0 0;
    display: flex;
    justify-content: space-between;
}
.art-slider-navigation .swiper-pagination-bullet {
    margin-right: 10px;
    border-radius: 5px;
    height: 4px;
    transition: 0.7s ease-in-out;
    @apply bg-black;
}
.art-slider-navigation .swiper-pagination-bullet.swiper-pagination-bullet-active{
    @apply bg-pallate-main;
    width: 20px;
}
.art-slider-nav{
    @apply hover:text-pallate-main text-black text-xl;
}
.swiper-button-prev-unique,.swiper-button-next-unique{
    @apply h-full top-0 bottom-0 my-auto absolute hover:text-pallate-main text-black text-3xl flex justify-center items-center cursor-pointer;
}
.swiper-button-disabled{
    @apply text-pallate-1/[0.2] hover:text-pallate-1/[0.2];
}
.swiper-button-prev-unique{
    @apply -left-10 ;
}
.swiper-button-next-unique{
    @apply -right-10 ;
}
/* ------------------------------ Slide ------------------------------ */
.homepage section.slide{
    @apply w-full bg-black py-0;
}
.slide .img{
    @apply w-full;
}
.slide img{
    @apply w-full opacity-60;
}
.swiper-slide-active img{
    @apply !scale-110 !shadow-lg;
}
/* ------------------------------ Left Menu ------------------------------ */
.left-menu{
    @apply flex flex-col w-1/3;
}
.left-menu input{
    @apply flex flex-col;
}
/* ------------------------------ Page ------------------------------ */
ul[type=disc],ul[type=circle]{
    @apply p-0 m-0;
}
ul[type=disc] span,ul[type=circle] span{
    @apply inline;
}
ul[type=disc] li.MsoNormal{
    list-style: disc inside none;
    display: list-item;
    margin-left: 1em;
    @apply my-0 py-0 leading-none;
}
ul[type=circle] li.MsoNormal{
    list-style: circle inside none;
    display: list-item;
    @apply my-0 py-0 leading-none;
}
ul[type=disc] li ul{
    @apply ml-5 indent-0 leading-4;
}
.center{
    @apply w-full text-center;
}
.article-one-image{
    @apply w-full relative flex items-end justify-end;
}
.article-one-image.right{
    @apply justify-start;
}
.article-one-image .article-bg{
    @apply absolute top-0 left-0 w-full bg-cover bg-center bg-no-repeat h-full;
    /* background-position: top center; */
}
.article-one-image .article-content{
    @apply p-10 w-full md:w-1/2 h-full z-10 bg-purple-2 bg-opacity-70 text-white;
}
.article-one-image .article-content h1{
    @apply py-5 text-white;
}
.article-one-image .article-content .article-detail{
    @apply text-base whitespace-pre-line;
}
.article-detail.container{
    @apply flex flex-col gap-0 px-5;
}
.article-content .article-detail h1{
    @apply py-2;
}
.article-content .article-detail p{
    @apply py-1;
}
.article-content .article-detail ul{
    @apply py-1;
}
.article-content h3{
    @apply text-center text-pallate-3;
}
.indent{
    @apply indent-16;
}
.article-full-content{
    @apply w-full flex justify-center items-center rounded-lg bg-pallate-1 py-5;
}
.article-full-content h3{
    @apply text-3xl leading-loose font-light text-red-pallate-3;
}
.article-detail table tr{
    @apply flex lg:table-row flex-wrap;
}
.article-detail table tr td{
    @apply w-full lg:w-auto first:w-full lg:first:w-[50%];
}
.article-full-content .location-img{
    @apply flex-wrap my-5;
}
.article-content ul li{
    @apply list-disc list-inside marker:text-purple-1;
}
.article-gallery{
    @apply w-full mx-auto;
}
.article-gallery .article-content .cards{
    @apply grid grid-cols-2 md:grid-cols-4 gap-1 mt-5;
}
.article-gallery .article-content .cards.cards-6{
    @apply grid grid-cols-3 md:grid-cols-6 gap-1 mt-5;
}
.article-gallery .article-content .cards.cards-3{
    @apply grid grid-cols-1 md:grid-cols-3 gap-1 mt-5;
}
.article-gallery .article-content .cards.small{
    @apply flex flex-row flex-wrap justify-center gap-4 mx-auto w-fit;
}
.article-gallery .article-content .cards.small .card{
    @apply w-[220px] h-[138px] border-white border-4 rounded shadow;
}
.article-gallery .article-content .cards.small .card .card-title{
    @apply hidden;
}
.article-gallery .article-content .card{
    @apply col-span-1;
}
.article-gallery .card{
    @apply relative overflow-hidden cursor-default;
}
.article-gallery .card:hover .card-overlay{
    @apply opacity-100;
}
.article-gallery .card-overlay{
    @apply absolute bg-black/[0.8] h-full w-full left-0 top-0 bottom-0 right-0 opacity-0 ease-out duration-300 hover:ease-in;
    /* -webkit-transition: all 0.4s ease-in-out 0s;-moz-transition: all 0.4s ease-in-out 0s;transition: all 0.4s ease-in-out 0s */
}
.article-gallery .card-details{
    @apply absolute text-center px-4 w-full text-white top-1/2 left-1/2 opacity-0 ease-in-out duration-300 hover:ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s */
}
.article-gallery .card:hover .card-details{
    @apply opacity-100 top-1/2 left-1/2 text-lg;
}
.article-gallery .card .card-details .card-text{
    @apply text-sm py-5 ;
}
.article-gallery .card .card-details .card-text button{
    @apply text-gold-100/[.9];
}
.article-map-content{
    @apply w-full relative flex gap-0 min-h-[400px];
}
.article-map-content.container{
    @apply w-full relative flex gap-0 min-h-[400px];
}
.article-map-content .map{
    @apply w-full absolute min-h-[400px] z-0;
}
.article-detail iframe{
    @apply pr-[30px];
}
.article-map-content .map iframe{
    @apply w-full min-h-[400px];
}
.article-map-content .article-content{
    @apply p-10 w-1/2 md:w-1/2 absolute -top-10 h-fit min-h-[470px] right-0 bg-purple-2 bg-opacity-80 text-white z-10;
}
.article-map-content .article-content h1{
    @apply py-5 text-white;
}
.article-map-content .article-content .article-detail{
    @apply text-base whitespace-pre-line;
}
.article-map-content .article-detail ul li{
    @apply list-none;
}
.article-map-content .article-detail p{
    @apply text-sm;
} 
.article-content .social ul li{
    @apply mr-4 p-4 cursor-pointer rounded-full bg-white/[0.7] shadow-lg text-purple-2 hover:text-gold-100;
}
.article-cover {
    @apply w-full py-5;
}
.article-cover img{
    @apply w-auto max-h-[400px] mx-auto;
}
.swiper{
    @apply w-full;
}
.ebook-swiper-pagination {
    @apply hidden;
}
.cookie-banner{
    @apply w-[420px] fixed left-5 bottom-5 rounded-lg bg-black bg-opacity-80 text-white px-8 py-5 z-20;
}
.cookie-banner{
    @apply flex flex-col;
}
.cookie-banner p{
    @apply py-2;
}
.cookie-banner a{
    @apply text-red-pallate-3 hover:underline ml-2;
}
.cookie-banner button{
    @apply rounded-lg w-full shadow-lg bg-white text-red-pallate-3 hover:bg-red-pallate-1 hover:text-white mb-2 py-2;
}
.cookie-banner button.accept{
    @apply bg-red-pallate-3 text-white hover:bg-red-pallate-2;
}
.text-small .cookie-banner h2{
    @apply text-sm;
}
.text-normal .cookie-banner p{
    @apply text-base;
}
.text-large .cookie-banner p{
    @apply text-lg;
}
.text-small .cookie-banner p{
    @apply text-base;
}
.text-normal .cookie-banner p{
    @apply text-lg;
}
.text-large .cookie-banner p{
    @apply text-lg;
}
.backscreen{
    @apply fixed w-full h-full bg-black bg-opacity-70 left-0 right-0 top-0 bottom-0 z-[51];
}
.modal-dialog{
    @apply  rounded-lg mx-auto w-full h-full flex justify-center items-center ;
}
.cookie-modal{
    @apply relative  rounded-lg bg-white mx-auto my-auto z-[55] px-8 py-8 max-w-[454px];
}
.cookie-modal p{
    @apply  mt-2;
}
.cookie-modal .close{
    @apply absolute right-5 top-5 cursor-pointer hover:text-pallate-main;
}
.text-small .cookie-modal p{
    @apply text-base;
}
.text-normal .cookie-modal p{
    @apply text-base;
}
.text-large .cookie-modal p{
    @apply text-base;
}
.button-comfirm{
    @apply w-full flex justify-end;
}
.cookie-modal ul{
    @apply my-5;
}
.button-comfirm button{
    @apply text-white bg-pallate-4 hover:bg-pallate-3 focus:ring-4 focus:outline-none focus:ring-pallate-3/[0.5] rounded-lg text-base w-full sm:w-auto px-5 py-2.5 text-center dark:bg-pallate-3;
}
.cookie-modal label{
    @apply relative inline-flex items-center cursor-pointer;
}
.cookie-modal label span{
    @apply ml-3 text-sm;
}
.toggle-div{
    @apply w-11 h-6 bg-gray-200 rounded-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ;
}
.toggle-div.active{
    @apply focus:ring-4 focus:ring-blue-300 after:translate-x-full after:border-white bg-blue-600;
}
/* calendar */
.fc table{
    @apply mt-0 bg-white;
}
.fc-scroller {
    @apply md:h-max h-auto;
    /* height: auto !important; */
}
.fc-head .fc-widget-header {
    @apply md:mr-auto mr-0;
    /* margin-right: 0 !important; */
}
.fc-scroller {
    @apply md:overflow-y-auto overflow-visible;
    /* overflow: visible !important; */
}
.fc-event-dot{
    @apply content-['']
}
table tr,table td,table thead,table tbody{
    @apply hover:bg-transparent
}
.fc-daygrid-event-harness{
    @apply !text-white !bg-pallate-3/[0.5] rounded mx-[0.5px] mb-[1px]; 
}
.fc-event-dot{
    @apply before:content-['\25CF'] text-xs px-2 pr-1 text-pallate-4;
}
.fc-theme-standard .fc-scrollgrid{
    @apply border-0 rounded-lg;
}
.fc a.fc-col-header-cell-cushion{
    @apply py-2
}
.fc-toolbar-chunk h2.fc-toolbar-title{
    @apply text-lg px-1;
}
.fc-daygrid-dot-event .fc-event-title{
    @apply !font-normal;
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
    @apply !text-pallate-4;
}
.fc-daygrid-dot-event.fc-event-mirror, .fc-daygrid-dot-event:hover{
    @apply !bg-transparent;
}   
.fc .fc-prev-button, .fc .fc-next-button{
    @apply !rounded-[0.25em] w-[32px] h-[32px] flex justify-center items-center !bg-transparent hover:!bg-pallate-3/[0.5] !text-pallate-3 hover:!text-white;
}
.fc .fc-view-harness{
    @apply !min-h-[380px];
}
.calendar .fc-direction-ltr .fc-button-group>.fc-button{
    @apply !rounded-[0.25em] w-[32px] h-[32px] flex justify-center items-center bg-transparent hover:bg-pallate-3/[0.5] text-pallate-3 hover:text-white;
}
.calendar .fc-toolbar-chunk h2.fc-toolbar-title{
    @apply text-lg px-5;
}
.calendar .fc .fc-button-primary{
    /* @apply px-4 text-sm bg-pallate-1/[0.15] text-pallate-3 hover:text-pallate-3 hover:bg-pallate-1/[0.3] !ring-0 !border-0; */
    @apply px-4 text-sm bg-pallate-3 text-white hover:text-white hover:bg-pallate-2 !ring-0 !border-0 cursor-pointer;
}
.calendar .fc .fc-button-primary:disabled{
    @apply bg-pallate-3/[0.3] text-dark-2/[0.3] hover:bg-pallate-3/[0.3] hover:text-dark-2/[0.3];
}
.calendar .fc .fc-prev-button,.calendar .fc .fc-next-button{
    @apply px-3 text-sm;
}
.calendar .fc .fc-button-primary:not(:disabled).fc-button-active,.calendar .fc .fc-button-primary:not(:disabled):active{
    @apply bg-pallate-3/[0.3];
}
.calendar .fc .fc-daygrid-more-link{
    @apply text-pallate-3 text-[0.9rem];
}
.fc .fc-toolbar.fc-header-toolbar{
    @apply !my-2;
}
.fc-toolbar-chunk{
    @apply flex;
}
.fc button.fc-button{
    @apply flex justify-center items-center
}
.fc-myCustomButton2-button::before{
    content: "\f073";
    font: var(--fa-font-solid);
    @apply mr-1;
}
.fc .fc-popover{
    @apply !z-10;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    @apply !mx-0;
}
.swal2-container table{
@apply w-full mt-0;
}
.swal2-container thead,.swal2-container th{
@apply text-gray-400 text-sm font-thin uppercase p-2 py-3 text-center;
}
.swal2-container td{
@apply p-2 text-sm font-thin text-left ;
}
.swal2-container tr{
@apply border-b border-dashed border-gray-200 hover:bg-gray-100;
}
.swal2-container td.center{
@apply text-center;
}
.swal2-container td.right{
@apply text-right;
}
/** Table **/
.rdt_Table{
    @apply w-full mt-2;
    @apply !bg-transparent shadow-lg;
}
.rdt_TableHeadRow .rdt_TableCol{
    @apply text-gray-400 text-sm font-thin uppercase p-2 py-3 text-center;
    @apply text-pallate-3 font-thin p-3 first:rounded-tl-lg last:rounded-tr-lg;
    @apply  bg-opacity-60 bg-pallate-1/[0.3] border-none min-h-max;
    @apply flex justify-center items-center;
}
.rdt_TableCell{
    @apply p-2 text-sm font-thin text-left ;
}
.rdt_TableCell p{
    @apply text-sm font-thin text-left ;
}
.rdt_TableRow{
    @apply border-b border-dashed border-gray-200 hover:bg-gray-100;
}
.rdt_TableCell button{
    @apply p-1 mx-1 text-gray-600;
}
.rdt_TableCell{
    @apply text-gray-600;
}
.rdt_TableHeadRow{
    @apply !bg-transparent;
}
.rdt_Pagination{
    @apply flex items-center;
}
.rdt_Pagination button{
    @apply rounded px-1 py-1 text-pallate-3 mr-2 hover:text-white hover:bg-pallate-1/[0.3] h-[32px] w-[32px] flex justify-center cursor-pointer;
}
.rdt_Pagination svg{
    @apply w-[20px] fill-pallate-3 block  hover:fill-white border-0;
}
.rdt_Pagination button[aria-disabled="true"]{
    @apply text-white hover:bg-purple-200 hover:bg-opacity-60;
}
.rdt_Pagination button[aria-disabled="true"] svg{
    @apply fill-gray-300;
}
.rdt_Pagination select{
    @apply min-w-max mb-0 box-border h-[32px] mx-2 text-sm rounded-sm;
    @apply focus:border-2 focus:border-pallate-3 focus:outline-none focus:outline-pallate-3;
}
.rdt_Pagination div{
    @apply my-auto flex items-center;
}
.rdt_Pagination div svg{
    @apply top-auto;
}
.calendar header{
    @apply bg-transparent px-0 z-0;
}
.datatable-filter{
    @apply py-0 flex flex-col lg:flex-row w-full;
}
.datatable-filter .input-group{
    @apply mb-2 lg:mb-0 w-full;
    @apply flex flex-row;
}
.datatable-filter .input-group input{
    @apply w-full first:w-full first:lg:w-1/3;
}
.datatable-filter input{
    @apply border rounded w-full py-2 px-3 text-gray-700 min-w-[240px] focus:outline-pallate-3;
}   
.datatable-filter .input-group .btn{
    @apply w-full ml-0 lg:ml-2 py-2 text-sm bg-pallate-1/[0.15] text-pallate-3 hover:text-pallate-3 hover:bg-pallate-1/[0.3] !ring-0 !border-0 rounded;
}
.datatable-filter .input-group .btn:not(:first-child){
    @apply ml-2;
}
.datatable-filter input[type=text]{
    @apply w-full;
}
.a-links{
    @apply text-pallate-3 hover:underline cursor-pointer;
}
.login .alert.alert-info,.login .alert.alert-danger{
    @apply rounded-lg bg-white bg-opacity-90 shadow-lg p-3 flex items-center justify-center w-2/3 lg:w-1/3;
    @apply bg-pallate-3/[0.15] border border-pallate-3 rounded w-full py-5 my-5; 
}
.login .alert.alert-danger{
    @apply border-red-600 bg-red-50 text-red-600;
}
.login .alert.alert-info p,.login .alert.alert-danger p{
    @apply text-left w-full ;
}
.login .alert.alert-info b,.login .alert.alert-danger b{
    @apply text-pallate-3
}
.login .alert.alert-danger p{
    @apply text-red-500;
}
.login p.float-right{
    @apply flex whitespace-nowrap;
}
.login p.float-right i{
    @apply mr-2;
}
.login .a-links{
    @apply cursor-pointer text-pallate-3 text-right w-full hover:text-pallate-3;
}
/** Notice **/
.notice{
    @apply rounded mb-3 py-3 border border-dashed border-purple-450 bg-purple-100 text-purple-450 flex flex-row justify-between items-center;
}
.notice i{
    @apply text-2xl px-7;
}
.notice .info{
    @apply grow;
}
.notice button{
    @apply mx-7;
}
.notice a.info{
    @apply text-purple-450 px-0 hover:text-purple-250 text-sm;
    @apply hover:underline;
}
.notice p{
    @apply pt-1 text-left;
}
.notice .info h2{
    @apply text-left p-0;
}
.notice.warning{
    @apply border-yellow-500 bg-yellow-50 text-yellow-400;
}
.notice.warning a.info,.notice.warning .info h2 {
    @apply text-yellow-400;
}
.notice.info{
    @apply border-blue-500 bg-blue-50 text-blue-400;
}
.notice.info a.info,.notice.info .info h2 {
    @apply text-blue-400;
}
.notice.danger{
    @apply border-red-600 bg-red-50 text-red-600;
}
.notice.danger a.info, .notice.danger .info h2 {
    @apply text-red-500;
}
.notice.active{
    @apply border-green-600 bg-green-100 text-green-600;
}
.notice.active a.info, .notice.active .info h2 {
    @apply text-green-600 first-letter:text-green-600;
}
.notice.active a.info{
    @apply hover:underline;
}
.icon{
    @apply rounded-full bg-pallate-2 w-[80px] h-[80px] relative mb-5;
}
.icon img{
    @apply  fill-white !shadow-none absolute !m-auto top-0 left-0 right-0 bottom-0 ;
    filter: invert(100%) sepia(0%) saturate(7497%) hue-rotate(124deg) brightness(106%) contrast(99%);
}
.homepage-layout header{
    @apply bg-transparent transition ease-linear delay-500;
}
.homepage-layout .header-slide{
    @apply bg-white drop-shadow;
}
.homepage-layout .header-slide ul li a{
    @apply  text-black hover:text-red-pallate-3;
}
.homepage-layout .header-slide ul li.active a{
    @apply  text-red-pallate-3;
}
.homepage-layout .logo .logo-img{
    @apply invisible transition ease-in delay-500;
}
.homepage-layout .header-slide .logo .logo-img{
    @apply visible;
}
.location-img{
    @apply flex justify-center items-center gap-5 w-full;
}
.location-img img{
    @apply h-[250px] w-[350px];
}
.acc-lists {
    @apply w-full flex flex-col gap-2 my-5;
}
.acc-lists li{
    @apply flex justify-center items-center gap-1 w-full;
}
.acc-lists img{
    @apply !rounded-none mx-0 !my-0 h-auto lg:h-[180px] w-[270px];
}
.acc-lists li{
    @apply flex-wrap 2xl:flex-nowrap;
}
.acc-lists.travel-lodge img{
    @apply h-[180px];
}
.acc-lists.travel-lodge-s img{
    @apply h-[180px];
}
.hotel tr td{
    @apply align-top first:pr-8;
}
.hotel .main-img{
    @apply flex justify-start items-start flex-col w-full;
}
.hotel .main-img-travel-lodge{
    @apply flex justify-end items-end flex-col w-full;
}
.hotel img{
    @apply w-full lg:w-full;
}
.hotel img.mini{
    @apply w-full lg:w-2/3;
}
.hotel img.minis{
    @apply w-full lg:w-4/5;
}
.hotel h2{
    @apply text-5xl font-semibold pb-5 leading-[1.25];
}
.hotel h4{
    @apply text-2xl font-semibold pb-3;
}
.hotel h5{
    @apply text-lg text-red-pallate-3 pt-3;
}
.hotel a,.hotel b{
    @apply text-slate-800;
}
.hotel p{
    @apply text-lg;
}
.acc-map{
    @apply p-5 bg-[#c5b7b6];
}
.acc-map .location-detail{
    @apply text-white align-bottom;
}
.acc-map .location-detail span{
    @apply inline-block min-w-[92px];
}
.acc-map .location-detail a{
    @apply !text-white hover:!text-red-pallate-3;
}
.rsvn-btn {
    @apply flex justify-start items-center ;
}
.rsvn-btn a{
    @apply hover:text-pallate-bg rounded-md hover:bg-white py-2 px-5 hover:shadow-md bg-pallate-3 text-white;
}
.acc-btn {
    @apply flex justify-start items-center py-5;
}
.acc-btn a{
    @apply bg-pallate-bg rounded-md text-white py-2 px-5 hover:shadow-md hover:bg-pallate-3 hover:text-white;
}
.register-page ul{
    @apply gap-5 flex w-full;
}
.register-page ul li{
    @apply w-full flex flex-col p-5 bg-[#dedede] py-8;
}
.register-page h3{
    @apply uppercase font-semibold text-2xl;
}
.register-page p{
    @apply text-center;
}
.register-page img{
    @apply mx-auto;
}
.register-page a{
    @apply text-center rounded-full text-white bg-purple-550 hover:bg-purple-450 uppercase py-3 px-8 mx-auto my-5;
}
.trip{
    @apply flex gap-5;
}
.trip li{
    @apply flex flex-col bg-[#f6defe] rounded-2xl p-5 py-14;
}
.trip li h4{
    @apply pb-8 text-xl;
}
.trip li img{
    @apply w-1/2 ;
}
.trip li a{
    @apply mx-auto bg-[#a668be] flex justify-center items-center flex-nowrap text-white rounded-full py-3 px-8 mt-5 hover:bg-opacity-80 hover:no-underline;
}
.trip li a span.gt{
    @apply rounded-full text-[#a668be] bg-white aspect-square mr-2 flex justify-center items-center w-[24px];
}
.ck-table-resized{
    @apply w-full text-center align-middle;
}
.table .text-left{
    @apply px-5 !text-left sm:whitespace-nowrap;
}