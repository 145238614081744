.slide-container {
    @apply p-0 m-0 w-full h-full relative bg-white;       /* Need to defind for SwiperNavigation */
}
.resource{
    @apply flex justify-center flex-col items-center;
}
.countdown{
    @apply text-[400px] text-purple-250/20 text-center font-bold min-h-[400px] absolute top-1/2 left-1/2 flex justify-center items-center;
    transform: translate(-50%, -50%);

}
.slide-container .title{
    @apply absolute top-1/2 left-1/2 text-center;
    transform: translate(-50%, -50%);
}
.slide-container .title h3{
    @apply tracking-widest text-3xl lg:text-[3.5rem] leading-loose mb-2 lg:mb-6;
}
.slide-container .title p{
    @apply text-xl lg:text-3xl font-thin text-purple-450;
}