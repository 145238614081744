.ebook-container{
    @apply flex flex-col;
}
.nav-menu{
    @apply flex w-full py-5;
}
.nav-menu li{
    @apply pr-5 after:ml-5;
    @apply flex after:content-['>'] last:after:content-[''] after:after:ml-0;
    @apply last:text-pallate-main;
}
.nav-menu li a{
    @apply hover:text-pallate-main;
}
.content{
    @apply w-full flex flex-nowrap;
}
.content-container{
    /* @apply w-full md:w-3/4 pr-5; */
    @apply w-full pr-5;
}
.book-shelf-container{
    @apply w-full flex flex-col;
}
.book-shelf-container h3{
    @apply pt-2;
}
.top-menu{
    @apply w-full flex justify-end;
}
.book-shelf{
    @apply w-full flex flex-wrap justify-start gap-0 mb-10;
}
.book-shelf li{
    @apply w-full sm:w-1/2 lg:w-1/2 xl:w-1/3 2xl:w-1/4 mt-5 pr-5 flex justify-start items-start;
}
.book-shelf-card{
    @apply h-full flex items-start w-full cursor-pointer flex-col rounded-lg shadow-xl p-2 py-2;
    @apply  border-gray-200 border;
}
.book-shelf .cover{
    @apply pt-2 w-full flex items-start justify-center pb-5 rounded-lg;
}
.cover img{
    @apply w-[153.6px] h-[201.6px] rounded-r-lg shadow-xl;
    @apply transition-all duration-500 ease-in-out transform;
}
li:hover .cover img{
    @apply scale-110 filter-none;
}
.book-info{
    @apply pb-2 px-2;
}
.book-shelf li:hover .book-shelf-card {
    @apply bg-pallate-main/[0.2];
}
.book-shelf li:hover .book-info .title{
    @apply text-pallate-4;
}
.book-shelf .book-info .published,.book-shelf-table .book-info .published{
    @apply text-pallate-main text-sm py-1;
}
.book-info .author{
    @apply text-gray-500 text-sm pt-2;
}
.book-info .isbn{
    @apply text-pallate-main text-sm pt-2;
}
.top-menu{
    @apply w-full flex justify-between pt-5;
}
.btn-group{
    @apply flex w-fit bg-white rounded-md shadow-sm divide-x divide-gray-200 border border-gray-200;
}
.btn-group button{
    @apply flex justify-center items-center w-fit text-sm p-2 px-4 h-full text-pallate-main mr-0 hover:border-pallate-main first:rounded-l-md last:rounded-r-md hover:text-white hover:bg-pallate-main;
}
.btn-group button i{
    @apply p-0 text-center;
}
.btn-group button.active{
    @apply text-white border-pallate-main bg-pallate-main;
}
.book-shelf-table{
    @apply w-full flex flex-wrap justify-between mb-10;
}
.book-shelf-table li{
    @apply w-full lg:w-1/2 mt-5 pr-5 flex justify-start items-start;
}
.book-shelf-table .book-shelf-card{
    @apply w-full flex items-start cursor-pointer flex-row rounded-lg shadow-xl p-2 py-4;
    @apply  border-gray-200 border;
    /* @apply  hover:bg-gray-200; */
    @apply  hover:bg-pallate-main/[0.2];
}
.book-shelf-table .cover{
    @apply w-full h-full my-auto flex items-center justify-center rounded-lg;
}
.book-shelf-table .cover img{
    @apply w-[153.6px] h-[201.6px] rounded-r-lg shadow-xl;
}
.book-shelf-table .book-info{
    @apply w-full flex items-start flex-col;
}
.book-shelf-table .book-info .review{
    @apply line-clamp-2 py-2;
}
.book-shelf-table li:hover .title {
    @apply text-pallate-4;
}
.book-detail{
    @apply flex justify-center px-10 py-8 bg-pallate-1/[0.2] rounded-lg mb-10 w-full;
}
.book-detail .cover{
    @apply min-w-fit pr-5;
}
.book-detail .cover img{
    @apply transition-all duration-500 ease-in-out transform cursor-pointer;
    @apply hover:scale-110 filter-none;
}
.book-detail .book-info{
    @apply w-4/5;
}
.book-detail .book-info .title{
    @apply text-gray-800 cursor-pointer hover:text-pallate-3 pb-3;
}
.book-detail .book-info p{
    @apply py-2 text-gray-700;
}
.book-detail .book-info span{
    @apply pr-5 text-pallate-3;
}
.book-detail .book-info a{
    @apply text-blue-pallate-4 hover:underline;
}
.book-detail .back{
    @apply flex justify-end items-end pt-5;
}
.book-detail .back a{
    @apply rounded bg-pallate-main text-white hover:bg-pallate-1 px-5 py-2 hover:no-underline;
}