.nav-menu{
    @apply flex w-full py-5 flex-col md:flex-row;
}
.nav-menu li{
    @apply pr-5 after:ml-5 pt-1 md:pt-0;
    @apply flex after:content-['>'] last:after:content-[''] after:after:ml-0;
    @apply last:text-pallate-main;
}
.nav-menu li a{
    @apply hover:text-pallate-main;
}
/* ------------------------------ Article ------------------------------ */
.article{
    @apply flex flex-col;
}
.article section.title{
    @apply w-full h-[320px] bg-black py-0 relative opacity-100;
}
.article .title h1{
    @apply text-white absolute w-full top-1/2 text-center text-7xl ;
}
.article .title .cover-img{
    @apply w-full h-[320px] bg-no-repeat opacity-80 bg-fixed;
    background-position: top 116px center;
}
.article .navigator ul{
    @apply flex flex-row items-center;
}
.article .navigator li {
    @apply pr-3 pt-12 pb-5 before:content-[">"] before:pr-3 first:before:content-[''] first:before:pr-0 text-gray-400 last:before:text-gray-400 last:text-black;
}
.article .container{
    @apply flex flex-row items-start gap-16;
}
.article .items{
    @apply w-full flex flex-col justify-start items-end;
}
.article .items .card{
    @apply flex flex-col w-full items-start justify-start;
}
.article .items .card iframe{
    @apply h-[350px] w-[680px];
}
.card .image{
    @apply h-[389px] w-full relative object-cover overflow-hidden flex items-center cursor-pointer;
}
.card .image-inner{
    @apply w-full h-full absolute bg-cover bg-center transition-all duration-500 ease-in-out transform;
}
.card .info{
    @apply py-8 px-8 relative w-full;
}
.card .date{
    @apply w-fit text-gold-100 flex flex-row items-center text-sm whitespace-pre py-3;
}
.card .day{
    @apply row-span-2 text-7xl font-extralight text-black mr-3;
}
.card .month,.card .year{
    @apply text-xl italic;
}
.card .title{
    @apply text-2xl hover:text-gold-100 cursor-pointer h-auto bg-transparent text-left;
}
.card .detail p{
    @apply whitespace-pre-line text-gray-400 text-base;
}
.card .detail{
    @apply whitespace-pre-line text-gray-400 text-base;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.card .viewmore{
    @apply text-purple-3 hover:underline pt-3 pb-5 cursor-pointer text-sm;
}
.card .card-end{
    @apply border-b border-dashed w-full border-gray-300 absolute bottom-5 left-0 right-0;
}


/* ------------------------------ Article card-2 ------------------------------ */
.card-2{
    @apply flex flex-row w-full items-start justify-start gap-5 mb-8;
}
.card-2 .image{
    @apply h-[290px] w-1/2 relative object-cover overflow-hidden flex items-center cursor-pointer shadow-lg;
}
.card-2 .image-inner{
    @apply w-full h-full absolute bg-cover bg-center transition-all duration-500 ease-in-out transform;
}
.card-2 .info{
    @apply py-8 px-8 relative w-1/2;
}
.card-2 .date{
    /* @apply grid grid-cols-2 w-fit  text-gray-400; */
    @apply w-fit text-gold-100 flex flex-row items-center text-sm whitespace-pre pt-3;
}
.card-2 .day{
    @apply row-span-2 text-7xl font-extralight text-black mr-3;
}
.card-2 .month,.card-2 .year{
    @apply text-xl italic;
}
.card-2 .title{
    @apply text-xl hover:text-gold-100 cursor-pointer h-auto bg-transparent text-left;
}
.card-2 .detail{
    @apply whitespace-pre-line text-gray-400 text-base pt-3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.card-2 .detail p{
    @apply whitespace-pre-line text-gray-400 text-base;
}
.card-2 .viewmore{
    @apply text-purple-3 hover:underline pt-3 pb-5 cursor-pointer text-base;
}
.card-2 .card-end{
    @apply border-b border-dashed w-full border-gray-300 absolute bottom-5 left-0 right-0;
}

/* ------------------------------ Article Menu ------------------------------ */
.menu{
    @apply w-1/4 mb-10 hidden md:flex flex-col justify-start;
}
.menu.right{
    @apply order-last;
}
.menu .search{
    @apply flex flex-nowrap items-stretch w-full mb-4 flex-row;
}
.menu .search-input{
    @apply flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-l transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gold-100 focus:outline-none;
}
.menu .search button{
    @apply px-6 py-2.5 bg-gold-100/[0.8] text-white font-medium text-xs leading-tight uppercase rounded-r shadow-md hover:bg-gold-100 hover:shadow-lg focus:bg-gold-100  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gold-100 active:shadow-lg transition duration-150 ease-in-out flex items-center;
}
.menu-section h2{
    @apply py-6 border-b border-gray-300 mb-4;
}
.menu .category li{
    @apply flex flex-row justify-around py-4; 
}
.menu .category li .total_article{
    @apply min-w-[30px] h-[30px] text-xs bg-gray-700 text-white flex items-center justify-center; 
}
.menu .category li.active .total_article,.menu .category li:hover .total_article{
    @apply bg-gold-100; 
}
.menu .category li a{
    @apply w-full flex pl-5 hover:text-gold-100; 
}
.menu .category li.active a{
    @apply text-gold-100; 
}
.post-container {
    @apply p-0 m-0 w-full pt-2;       /* Need to defind for SwiperNavigation */
}
.post-container .card{
    @apply relative flex flex-col;
}
.post-container .card .image{
    @apply h-[270px] min-w-[370px] relative object-cover overflow-hidden flex items-center cursor-pointer;
}
.post-container .card .image-inner{
    @apply w-full h-full absolute bg-cover bg-center transition-all duration-500 ease-in-out transform;
}
.post-container .card:hover .image-inner {
    @apply scale-110 filter-none;
}
.post-container .create-time{
    @apply text-base w-full text-center text-purple-3;
}
.post-container .card .title{
    @apply text-xl hover:text-gold cursor-pointer h-auto bg-transparent text-center py-4;
}
.post-container .card .detail{
    @apply whitespace-pre-line text-gray-500 py-5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
/* ------------------------------ Article Content ------------------------------ */
.article-container{
    @apply w-4/5 mx-auto;
}
.article-content{
    @apply w-full mx-auto;;
}
.article-content{
    @apply bg-pallate-1/[0.2] p-8 rounded-lg mb-8;
}
.article-detail img{
    @apply inline-block;
}
.article-cover img{
    @apply w-auto max-h-[400px] mx-auto pt-5;
}
.article-content h3{
    @apply pt-5 text-pallate-main text-center leading-loose !font-normal;
}
.article-content a{
    @apply text-red-pallate-main hover:underline;
}
.article-detail{
    @apply py-5 whitespace-pre-wrap;
}
.article-items{
    @apply pb-10;
}
.article-item{
    @apply my-10 first:mt-0;
}
.article-nav{
    @apply w-full flex justify-end gap-5 mb-10;
}
.btn-group{
    @apply flex w-fit bg-white rounded-md shadow-sm divide-x divide-gray-200 border border-gray-200;
}
.btn-group button{
    @apply flex justify-center items-center w-fit text-sm p-2 px-4 h-full text-pallate-main mr-0 hover:border-pallate-main first:rounded-l-md last:rounded-r-md hover:text-white hover:bg-pallate-main;
}
.btn-group button i{
    @apply p-0 text-center;
}
.btn-group button.active{
    @apply text-white border-pallate-main bg-pallate-main;
}
.article td img{
    @apply my-2 shadow-lg rounded;
}
/* ------------------------------ Article Gallery ------------------------------ */
.article-gallery{
    @apply w-full mx-auto flex flex-col;
}
.article-content-title{
    @apply pb-5;
}
.article-gallery .article-content .cards{
    @apply grid grid-cols-2 md:grid-cols-4 gap-1 mt-5;
}
.article-gallery .article-content .cards.cards-6{
    @apply grid grid-cols-3 md:grid-cols-6 gap-1 mt-5;
}
.article-gallery .article-content .cards.cards-3{
    @apply grid grid-cols-1 md:grid-cols-3 gap-1 mt-5;
}
.article-gallery .article-content .cards.small{
    @apply flex flex-row flex-wrap justify-center gap-4 mx-auto w-fit;
}
.article-gallery .article-content .cards.small .card{
    @apply w-[220px] h-[138px] border-white border-4 rounded shadow;
}
.article-gallery .article-content .cards.small .card .card-title{
    @apply hidden;
}
.article-gallery .article-content .card{
    @apply col-span-1;
}
.article-gallery .card{
    @apply relative overflow-hidden cursor-default;
}
.article-gallery .card:hover .card-overlay{
    @apply opacity-100;
}
.article-gallery .card-overlay{
    @apply absolute bg-black/[0.8] h-full w-full left-0 top-0 bottom-0 right-0 opacity-0 ease-out duration-300 hover:ease-in;
    /* -webkit-transition: all 0.4s ease-in-out 0s;-moz-transition: all 0.4s ease-in-out 0s;transition: all 0.4s ease-in-out 0s */
}
.article-gallery .card-details{
    @apply absolute text-center px-4 w-full text-white top-1/2 left-1/2 opacity-0 ease-in-out duration-300 hover:ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s */
}
.article-gallery .card:hover .card-details{
    @apply opacity-100 top-1/2 left-1/2 text-lg;
}
.article-gallery .card .card-details .card-text{
    @apply text-sm py-5 ;
}
.article-gallery .card .card-details .card-text button{
    @apply text-gold-100/[.9];
}