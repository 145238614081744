/* ------------------------------ Footer ------------------------------ */
.footer{
    @apply bg-pallate-3 text-white;
}
.footer-container{
    @apply flex items-start flex-nowrap;
    @apply flex-col md:flex-row;
}
.footer{
    @apply flex items-start justify-start flex-col;
}
.footer h3{
    @apply  uppercase pt-5;
}
.footer-info{
    @apply w-full h-full flex flex-col justify-start items-start md:pl-10 pt-10 md:pt-0;
}
.footer-info p{
    @apply pt-3 whitespace-pre;
}
.social-info{
    @apply w-full h-full flex flex-col justify-start items-start;
}
.social-link{
    @apply w-full flex mb-5;
}
.social-link li{
    @apply p-3 ml-5 rounded-full bg-white hover:bg-pallate-3 text-pallate-3 hover:text-white cursor-pointer;
}
.social-info p{
    @apply pt-3;
}
.footer-menu a{
    @apply before:content-['●'] before:mr-2 flex items-baseline hover:text-pallate-4 w-full;
}
.copyright{
    @apply w-full text-sm border-t border-dashed border-white border-opacity-20 mt-10 flex flex-col md:flex-row justify-between md:pr-10 2xl:pr-20;
}
.copyright a{
    @apply hover:text-pallate-4 text-xs;
}
.social-link li a{
    @apply  text-xl hover:text-white;
}
.visitors li{
    @apply w-full hover:text-red-600 cursor-pointer;
}
.visitors p{
    @apply w-full flex justify-between;
}
.visitors p span{
    @apply pl-28;
}